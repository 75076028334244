import React from 'react'
import Image from "next/legacy/image";
import { formatEventDate } from "../../../utilities/eventDateFormat"

export default function FeaturedEvent({ item }) {
  return (
    <div className={`content-row ${item.type}-type`} >
        {
            item.featuredEvent && (
                <div className="container">
                    <div>
                        <div>
                            {
                                item.featuredEvent[0]?.featuredImage && (
                                    <div className='image-wrapper'>
                                        <Image
                                            src={`${item.featuredEvent[0].featuredImage.node.sourceUrl}`}
                                            layout='fill'
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div>
                            <h4 dangerouslySetInnerHTML={{__html: item.featuredEvent[0].title}}></h4>
                            {
                                item.featuredEvent[0].venue && item.featuredEvent[0].venue.url && (
                                    <a href={`${item.featuredEvent[0].venue.url}`}>
                                      <div dangerouslySetInnerHTML={{__html: item.featuredEvent[0].venue.title}} className='event-category'></div>
                                    </a>
                                )
                            }
                            {
                                item.featuredEvent[0].venue && !item.featuredEvent[0].venue.url && (
                            
                                    <div dangerouslySetInnerHTML={{__html: item.featuredEvent[0].venue.title}} className='event-category'></div>
                                )
                            }
                            <div className="event-list-details">
                                <p dangerouslySetInnerHTML={{__html: formatEventDate(item.featuredEvent[0].startDate, item.featuredEvent[0].endDate, item.featuredEvent[0].allDay)}}></p>
                            </div>
                            {
                                item.featuredEvent[0].venue && (
                                    <div className="event-venue-details">
                                        {
                                            item.featuredEvent[0].venue.city && !item.featuredEvent[0].venue.state && (
                                                <p>{item.featuredEvent[0].venue.city}</p>
                                            )
                                        }
                                        {
                                            !item.featuredEvent[0].venue.city && item.featuredEvent[0].venue.state && (
                                                <p>{item.featuredEvent[0].venue.state}</p>
                                            )
                                        }
                                        {
                                            item.featuredEvent[0].venue.city && item.featuredEvent[0].venue.state && (
                                                <p>{item.featuredEvent[0].venue.city}, {item.featuredEvent[0].venue.state}</p>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                item.featuredEvent[0]?.excerpt && (
                                    <div dangerouslySetInnerHTML={{__html: item.featuredEvent[0]?.excerpt}}></div>
                                )
                            }
                            <a aria-label={`${item.featuredEvent[0]?.title}`} href={`${item.featuredEvent[0]?.uri}`} className="button red">
                                event details
                            </a>
                        </div>
                    </div>
                </div>
            )
        }
    </div>
  )
}