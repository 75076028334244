import dynamic from 'next/dynamic'
import Head from 'next/head'
import Header from '../components/Header';
import StoneleighHeader from '../components/StoneleighHeader';
import PageBuilder from '../components/PageBuilder';
import StoneleighPageBuilder from '../components/StoneleighPageBuilder';
import YoastSEO from '../components/YoastSEO';
import CTA from '../components/CTA';
import { client } from '../lib/apollo';

import { GET_PAGE, GET_404_DATA, GET_HEADER_OPTIONS, GET_FOOTER_OPTIONS } from '../queries/queries';
import Layout from '../components/Layout';
import Script from 'next/script'

const DynamicFooter = dynamic(() => import('../components/Footer'))
const DynamicFooterStoneleigh = dynamic(() => import('../components/StoneleighFooter'))

export default function SlugPage({ pageData }) {
  const page = pageData?.page;
  const headerData = pageData?.headerData;
  const footerData = pageData?.footerData;
  const cta = pageData?.page?.pageCallToAction?.callToAction ? pageData?.page?.pageCallToAction?.callToAction[0] : null;
  let seoData = pageData?.page.seo;
  const errorContent = pageData?.noPageData?.data?.siteOptions?.siteOptions?.errorContent;
  
  if(!seoData){
    seoData = {
      title: '404'
    };
  }

  return (
    <Layout>
      <div>
        <Script defer={true}>
          {`(function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-KJ842L9');`}
        </Script>
        <YoastSEO
          page={seoData}
          MetaRenderElement={Head}
          meta={<>
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="True"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
            <title>Natural Lands</title>
            <link rel="icon" href="/assets/favicon/favicon.ico"></link>
          </>}
        ></YoastSEO>
          {
              page && page?.template?.templateName === "Default" && (
                <Header header={headerData}></Header>
              )
          }
          {
            page && page?.template?.templateName === "Stoneleigh Page Builder" && (
              <StoneleighHeader header={headerData}></StoneleighHeader>
            )
          }
          {
            !page && (
              <Header header={headerData}></Header>
            )
          }
        <div id="content" role="main">
          {
            page && page?.template?.templateName === "Default" &&(
              <PageBuilder
                rows={page.pageBuilder?.contentRows}
                footerData= {footerData}
              ></PageBuilder>
            )
          }
          {
            page && page?.template?.templateName === "Stoneleigh Page Builder" &&(
              <StoneleighPageBuilder
                rows={page.pageBuilder?.contentRows}
                footerData= {footerData}
              ></StoneleighPageBuilder>
            )
          }
          {
            !page && (
              <div id="error-container" className='container'>
                <div dangerouslySetInnerHTML={{__html: errorContent}}></div>
              </div>
            )
          }
          {
            cta && (
              <CTA
                cta={cta}
              ></CTA> 
            )
          }
        </div>
          {
              page && page?.template?.templateName === "Default" && (
                <DynamicFooter footer={footerData}></DynamicFooter>
              )
          }
          {
            page && page?.template?.templateName === "Stoneleigh Page Builder" && (
              <DynamicFooterStoneleigh footer={footerData}></DynamicFooterStoneleigh>
            )
          }
          {
            !page && (
              <DynamicFooter footer={footerData}></DynamicFooter>
            )
          }
      </div>
    </Layout>
  )
}

export async function getStaticProps({ params }){
  const slug = params.uri.join("/");

  const response = await client.query({
    query: GET_PAGE,
    variables: { slug: slug }
  });

  const page = response?.data?.pageBy;

  const headerData = await client.query({
      query: GET_HEADER_OPTIONS
  });

  const footerData = await client.query({
    query: GET_FOOTER_OPTIONS
  });

  const noPageData = await client.query({
    query: GET_404_DATA
  });

  return {
    props: {
      pageData: {
        page: page,
        headerData: headerData,
        footerData: footerData,
        noPageData: noPageData,
      }
    },
    revalidate: 45,
  }
}

export async function getStaticPaths(){
    const paths = [];

    return {
        paths,
        fallback: 'blocking'
    }
}