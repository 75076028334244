import React from 'react'
import Image from "next/legacy/image";
import { formatPostDate } from "../../../utilities/dateFormat"

export default function FeaturedNews({ item }) {
  return (
    <div className={`content-row ${item.type}-type`} >
        <div className="container">
            <div>
                <div>
                    <div className='image-wrapper'>
                        <Image
                            src={`${item.featuredNews[0].featuredImage.node.sourceUrl}`}
                            layout='fill'
                        />
                    </div>
                </div>
                <div>
                <h4 dangerouslySetInnerHTML={{__html: item.featuredNews[0].title}}></h4>
                <div className="news-list-details">
                    <p>{formatPostDate(item.featuredNews[0].date)}</p>
                </div>
                {
                    item.featuredNews[0]?.excerpt && (
                        <div dangerouslySetInnerHTML={{__html: item.featuredNews[0]?.excerpt}}></div>
                    )
                }
                <a aria-label={`${item.featuredNews[0]?.title}`} href={`${item.featuredNews[0]?.uri}`} className="button red">
                    continue reading
                </a>
                </div>
            </div>
        </div>
    </div>
  )
}