import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function ItemTypeLeftImageRightContent({ item, smartImageLoader }) {
  let caption = '';

  if(item.image?.caption){
    caption = item.image?.caption.toString().replace(/<[^>]*>?/gm, '');
  }

  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.smallImage ? 'small-image ' : ''}${item.backgroundColor}-background ${item.type}-type`}>
      <div className="container">
        <div>
          <div>
            {
              item.image && (
                <>
                  {
                    item.image?.mediaDetails.length && (
                      item.image?.mediaDetails?.sizes?.map((image, index) => {
                        return (
                          image.width === '1140' && image.height === '960' && (
                            <div className='image-wrapper'>
                              {
                                smartImageLoader.renderImage(image)
                              }
                            </div>
                          )
                        )
                      })
                    )
                  }
                  {
                    !item.image?.mediaDetails.length && (
                      <div className='image-wrapper'>
                        {
                          smartImageLoader.renderImage(item.image)
                        }
                      </div>
                    )
                  }
                </>
              )
            }
            {
                caption != '' && (
                    <div className="page-builder-image-caption">
                        <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${caption}`}>
                            <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                        </a>
                        <ReactTooltip openOnClick={true} id="fmv-tooltip"></ReactTooltip>
                    </div>
                )
            }
          </div>
          <div>
            {
              item.content && (
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}