import React from 'react'
import Image from "next/legacy/image";
import { useState } from 'react';
import { decode } from 'html-entities';

import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'

export default function Gallery({ item }) {
   let [isOpen, setIsOpen] = useState(false);

   let newGallery = [];

   item.gallery.map((element) => {
    let newElement = {...element};

    if(newElement.caption){
        newElement.caption = decode(newElement?.caption?.toString().replace(/<[^>]*>?/gm, ''));
    }

    if(newElement.alt){
        newElement.alt = newElement?.alt?.toString().replace(/<[^>]*>?/gm, '');
    }

    if(newElement.description){
        newElement.caption += ' - ' + decode(newElement.description.toString().replace(/<[^>]*>?/gm, ''));
        newElement.alt += ' - ' + decode(newElement.description.toString().replace(/<[^>]*>?/gm, ''));
    }

    newGallery.push(newElement);
   });

  return (
    <div className={`content-row ${item.type}-type ${item.backgroundColor}-background`} >
        <div>
            <div>
                <div>
                    <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                    <button onClick={() => {setIsOpen(true)}} className='button red'>
                        View Gallery
                    </button>
                </div>
            </div>
            {
                item.backgroundImage && (
                    <div>
                        <div className='gallery-background-image'>
                            <Image
                                src={`${item.backgroundImage.sourceUrl}`}
                                layout='fill'
                            />
                        </div>
                        <div>
                            <SlideshowLightbox 
                                lightboxIdentifier="lightbox1" 
                                framework="next" 
                                images={newGallery} 
                                open={isOpen}
                                showThumbnails={true}
                                captionStyle={{color: "#d9d9d9"}}
                                onClose={() =>{setIsOpen(false)}}
                                downloadImages={true}
                            >
                                {newGallery.map((image) => (
                                    <Image
                                        src={image.src}
                                        data-lightboxjs="lightbox1"
                                        quality={100}
                                        layout='fill'
                                        alt={image.alt}
                                    />
                                ))}
                            </SlideshowLightbox>
                        </div>
                    </div>
                )
            }
            {
                !item.backgroundImage && (
                    <div>
                        <SlideshowLightbox 
                            lightboxIdentifier="lightbox1" 
                            framework="next" 
                            images={newGallery} 
                            open={isOpen}
                            showThumbnails={true}
                            captionStyle={{color: "#d9d9d9"}}
                            onClose={() =>{setIsOpen(false)}}
                        >
                            {newGallery.map((image) => (
                                <Image
                                    src={image.src}
                                    data-lightboxjs="lightbox1"
                                    quality={100}
                                    layout='fill'
                                    alt={image.alt}
                                />
                            ))}
                        </SlideshowLightbox>
                    </div>
                )
            }
        </div>
    </div>
  )
}