import React, { useEffect, useState } from 'react'
import { useRef, useCallback} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import Image from "next/legacy/image";
import { formatEventDate } from "../../../utilities/eventDateFormat"
import { wordpressUrl } from "../../../utilities/variables";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function EventsCarousel({ item, smartImageLoader }) {
  const sliderRef = useRef(null);
  const [eventsData, setEventsData] = useState([]);

  const fetchEvents = async() => {
    const eventList = await fetch(`${process.env.NEXT_PUBLIC_WORDPRESS_API_URL}/wp-json/naturallands/v1/events/?number_posts=10&category=${item.eventCategory.databaseId}`);
    const eventsData = await eventList.json();

    setEventsData(eventsData);
  }

  useEffect( () => {
    fetchEvents();
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const truncate = function(str) {
    return str.length > 48 ? str.substring(0, 45) + "..." : str;
  }

  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      <div className="container xlarge-container">
        <div className='events-carousel-header'>
            <div>
                <h2>{item.eventTitle}</h2>
            </div>
            <div>
                <a href={`${wordpressUrl}/events/`} className='button arrow'>
                    all events
                    <span className='icon-arrow'></span>
                </a>
            </div>
        </div>
        {
          eventsData && eventsData?.length > 0 && (
            <Swiper
              ref={sliderRef}
              modules={[Pagination]}
              loop
              speed={1000}
              slidesPerView={4}
              spaceBetween={20}
              breakpoints={{
                800: {
                    slidesPerView: 3,
                },
                600: {
                    slidesPerView: 2,
                },
                0: {
                    slidesPerView: 1,
                }
              }}
            >
              {
                eventsData.map((event, index) => {
                  return (
                    <SwiperSlide key={index} className={`event-${event.id}`}>
                      <div>
                        {
                          event.featuredImage && (
                            <div className='image-wrapper'>
                                <Image
                                    src={`${event.featuredImage}`}
                                    layout='fill'
                                    alt={`${event.featuredImageAlt}`}
                                />
                            </div>
                          )
                        }
                        <div className='event-content'>
                            <a href={`${event.url}`}>
                              <h4>{decodeURIComponent(truncate(event.title))}</h4>
                            </a>
                            {
                                event.venue && event.venueLink && (
                                    <a href={`${event.venueLink}`}>
                                      <div dangerouslySetInnerHTML={{__html: event.venue}} className='event-category'></div>
                                    </a>
                                )
                            }
                            {
                                event.venue && !event.venueLink && (
                                  <div dangerouslySetInnerHTML={{__html: event.venue}} className='event-category'></div>
                                )
                            }
                            <div className="event-list-details">
                                <p dangerouslySetInnerHTML={{__html: event.date}}></p>
                            </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          )
        }
        {
          eventsData.length && (
            <div className={eventsData.length < 4 ? 'events-navigation hide-desktop' : 'events-navigation' }>
                <div className="prev-arrow" onClick={handlePrev}>
                    <span className='icon-arrow'></span>
                </div>
                <div className="next-arrow" onClick={handleNext}>
                    <span className='icon-arrow'></span>
                </div>
            </div>
          )
        }
      </div>
    </div>
  )
}