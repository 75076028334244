import React from 'react';
import IframeResizer from "iframe-resizer-react";

export default function Form({ item, rows, index, urlParams, formUrl }) {

  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      <div className="container large-container">
        {
          item.content && (
            <div dangerouslySetInnerHTML={{__html: item.content}}></div>
          )
        }
        {
          item && !urlParams && (
            <div className="form-wrapper">
              <IframeResizer
                src={`${formUrl}/?gformId=${item.form}`}
                frameBorder="0"
                enablepublicmethods={true}
                checkOrigin={false}
                heightCalculationMethod="lowestElement"
                style={{ width: '1px', minWidth: '100%', minHeight: '100%' }}
              />
            </div>
          )
        }
        {
          item && urlParams && (
            <div className="form-wrapper">
              <IframeResizer
                src={`${formUrl}/?gformId=${item.form}&${urlParams}`}
                frameBorder="0"
                enablepublicmethods={true}
                checkOrigin={false}
                heightCalculationMethod="lowestElement"
                style={{ width: '1px', minWidth: '100%', minHeight: '100%' }}
              />
            </div>
          )
        }
      </div>
    </div>
  )
}