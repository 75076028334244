import React from 'react'
import { useRef, useCallback} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import Image from "next/legacy/image";
import { formatEventDate } from "../../../utilities/eventDateFormat"
import { wordpressUrl } from "../../../utilities/variables";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function StoneleighEventsCarousel({ item, smartImageLoader }) {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const truncate = function(str) {
    return str.length > 48 ? str.substring(0, 45) + "..." : str;
  }

  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      <div className="container xlarge-container">
        <div className='events-carousel-header'>
            <div>
                <h2>{item.eventTitle}</h2>
            </div>
            <div>
                <a href={`${wordpressUrl}/events/`} className='button arrow'>
                    all events 
                    <span className='icon-arrow'></span>
                </a>
            </div>
        </div>
        {
          item.eventCategory?.events?.nodes?.length > 0 && (
            <Swiper
              ref={sliderRef}
              modules={[Pagination]}
              loop
              speed={1000}
              slidesPerView={4}
              spaceBetween={20}
              breakpoints={{
                // when window width is >= 640px
                1051: {
                  slidesPerView: 4,
                },
                800: {
                    slidesPerView: 3,
                },
                600: {
                    slidesPerView: 2,
                },
                0: {
                    slidesPerView: 1,
                }
              }}
            >
              {
                item.eventCategory.events.nodes.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div>
                        <div className='image-wrapper'>
                            <Image
                                src={`${item.featuredImage.node.sourceUrl}`}
                                layout='fill'
                            />
                        </div>
                        <div className='event-content'>
                            <a href={`${wordpressUrl}${item.uri}`}>
                              <h4>{truncate(item.title)}</h4>
                            </a>
                            {
                                item.venue && item.venue.url && (
                                    <a href={`${item.venue.url}`}>
                                      <div dangerouslySetInnerHTML={{__html: item.venue.title}} className='event-category'></div>
                                    </a>
                                )
                            }
                            {
                                item.venue && !item.venue.url && (
                            
                                  <div dangerouslySetInnerHTML={{__html: item.venue.title}} className='event-category'></div>
                                )
                            }
                            <div className="event-list-details">
                                <p dangerouslySetInnerHTML={{__html: formatEventDate(item.startDate, item.endDate, item.allDay)}}></p>
                            </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          )
        }
        {
          item.eventCategory?.events?.nodes?.length > 4 && (
            <div className='events-navigation'>
                <div className="prev-arrow" onClick={handlePrev}>
                    <span className='icon-arrow'></span>
                </div>
                <div className="next-arrow" onClick={handleNext}>
                    <span className='icon-arrow'></span>
                </div>
            </div>
          )
        }
      </div>
    </div>
  )
}