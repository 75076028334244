import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import Image from "next/legacy/image";

export default function Widgets({ item }) {
  return (
    <div className={`content-row ${item.type}-type ${item.backgroundColor}-background`} >
        <div className="container xlarge-container">
          <div className='container large-container' dangerouslySetInnerHTML={{ __html: item.content }}></div>
          {
            item.twoColumnWidgets && item.type === 'two-column-widgets' && (
                <div className='container'>
                    <div className='widget-container'>
                        {
                            item.twoColumnWidgets.map((widget, index) => {
                                let caption = '';

                                if(widget.image?.caption){
                                    caption = widget.image?.caption.toString().replace(/<[^>]*>?/gm, '');
                                }

                                return(
                                <div className='widget'>
                                    {
                                        widget.image && (
                                            <div className='image-wrapper'>
                                                <Image
                                                    src={`${widget.image.sourceUrl}`}
                                                    layout='fill'
                                                    quality={100}
                                                    alt={widget.image.altText}
                                                />
                                                {
                                                    caption != '' && (
                                                        <div className="page-builder-image-caption">
                                                            <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${caption}`}>
                                                                <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                                                            </a>
                                                            <ReactTooltip openOnClick={true} id="fmv-tooltip"></ReactTooltip>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    <div dangerouslySetInnerHTML={{ __html: widget.content }}></div>
                                    {
                                        widget.button && (
                                            <div className='button-container' dangerouslySetInnerHTML={{ __html: widget.button }}></div>
                                        )
                                    }
                                </div>   
                            )})
                        }
                    </div>
                </div>
            )
          }
          {
            item.threeColumnWidgets && item.type === 'three-column-widgets' && (
                <div className='widget-container'>
                    {
                        item.threeColumnWidgets.map((widget, index) => {
                            let caption = '';

                            if(widget.image?.caption){
                                caption = widget.image?.caption.toString().replace(/<[^>]*>?/gm, '');
                            }

                            return(
                            <div className='widget'>
                                {
                                    widget.image && (
                                        <div className='image-wrapper'>
                                            <Image
                                                src={`${widget.image.sourceUrl}`}
                                                layout='fill'
                                                quality={100}
                                            />
                                            {
                                                caption != '' && (
                                                    <div className="page-builder-image-caption">
                                                        <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${caption}`}>
                                                            <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                                                        </a>
                                                        <ReactTooltip openOnClick={true} id="fmv-tooltip"></ReactTooltip>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                <div dangerouslySetInnerHTML={{ __html: widget.content }}></div>
                                {
                                    widget.button && (
                                        <div className='button-container' dangerouslySetInnerHTML={{ __html: widget.button }}></div>
                                    )
                                }
                            </div>   
                        )})
                    }
                </div>
            )
          }
          {
            item.fourColumnWidgets && item.type === 'four-column-widgets' && (
                <div className='widget-container'>
                    {
                        item.fourColumnWidgets.map((widget, index) => {
                            let caption = '';

                            if(widget.image?.caption){
                                caption = widget.image?.caption.toString().replace(/<[^>]*>?/gm, '');
                            }

                            return(
                            <div className='widget'>
                                {
                                    widget.image && (
                                        <div className='image-wrapper'>
                                            <Image
                                                src={`${widget?.image?.sourceUrl}`}
                                                layout='fill'
                                                quality={100}
                                            />
                                            {
                                                caption != '' && (
                                                    <div className="page-builder-image-caption">
                                                        <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${caption}`}>
                                                            <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                                                        </a>
                                                        <ReactTooltip openOnClick={true} id="fmv-tooltip"></ReactTooltip>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                <div dangerouslySetInnerHTML={{ __html: widget?.content }}></div>
                                {
                                    widget.button && (
                                        <div className='button-container' dangerouslySetInnerHTML={{ __html: widget.button }}></div>
                                    )
                                }
                            </div>   
                        )})
                    }
                </div>
            )
          }
        </div>
    </div>
  )
}