import React from 'react'
import { Tooltip as ReactTooltip } from "react-tooltip";
import Image from "next/legacy/image";

export default function FullWidthImage({ item }) {
  let caption = '';

  if(item.image?.caption){
    caption = item.image?.caption.toString().replace(/<[^>]*>?/gm, '');
  }

  return (
    <div className={`content-row ${item.type}-type`} >
        <Image
            src={`${item.image.sourceUrl}`}
            layout='fill'
            alt={`${item.image?.altText}`}
        />
        {
            caption != '' && (
                <div className="full-width-image-caption">
                    <a data-tooltip-id="fmv-tooltip" data-tooltip-content={`${caption}`}>
                        <img width={24} height={24} src="/assets/images/icon-photo-credit.svg" alt="Tooltip" />
                    </a>
                    <ReactTooltip openOnClick={true} id="fmv-tooltip"></ReactTooltip>
                </div>
            )
        }
    </div>
  )
}