import React from 'react'
export default function TableColumn({ item }) {
  return (
    <div className={`container content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type`}>
      {
        item.tableHeader && item.tableHeader.length > 0 && (
          <div className="table-header">
            {
              item.tableHeader.map((tableHeader, index) => {
                return (
                  <div key={index}>
                    <h3>{tableHeader.content}</h3>
                  </div>
                )
              })
            }
          </div>
        )
      }
      {
        item.tableRows && item.tableRows.length > 0 && (
          item.tableRows.map((tableCol, index) => {
            return (
              <div className="table-row" key={index}>
                {
                  tableCol.rowContent && tableCol.rowContent.length > 0 && (
                    tableCol.rowContent.map((content, index) => {
                      return (
                        <div>
                          <p>{content.content}</p>
                        </div>
                      )
                    })
                  )
                }
              </div>
            )
          })
        )
      }
    </div>
  )
}