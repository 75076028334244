import React from 'react'
export default function ImageWidget({ item, smartImageLoader }) {
  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.widgetsPerRow}-per-row ${item.type}-type`}>
      <div className="container">
        <div>
          {
            item.wysiwygTitle && (
              <div dangerouslySetInnerHTML={{ __html: item.wysiwygTitle }}></div>
            )
          }
        </div>
        <div>
          {
            item.imageWidgets?.length > 0 && (
              item.imageWidgets.map((imageWidget, index) => {
                return (
                  <div className="image-widget" key={index}>
                    {
                      imageWidget.image.mediaDetails && imageWidget.image?.mediaDetails?.sizes && (
                        <div className='image-wrapper'>
                          {
                            smartImageLoader.renderImage(imageWidget.image)
                          }
                        </div>
                      )
                    }
                    {
                      !imageWidget?.image?.mediaDetails?.sizes && (
                        <div className='image-wrapper'>
                          {
                            smartImageLoader.renderImage(imageWidget.image)
                          }
                        </div>
                      )
                    }
                    <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: imageWidget.content }}></div>
                    </div>
                  </div>
                )
              })
            )
          }
        </div>
      </div>
    </div>
  )
}