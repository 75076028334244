import React from 'react'
import Image from "next/legacy/image";

export default function Logos({ item }) {
  return (
    <div className={`content-row ${item.type}-type ${item.backgroundColor}-background`} >
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
          {
            item.threeLogos && item.type === 'three-column-logos' && (
                <div className='logos-container'>
                    {
                        item.threeLogos.map((logo, index) => {return(
                            <div className='logo'>
                                {
                                    logo.link && (
                                        <a href={`${logo.link}`} target='_blank'>
                                            <div className='image-wrapper'>
                                                <Image
                                                    src={`${logo.logo.sourceUrl}`}
                                                    width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                    height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                    quality={100}
                                                    alt={`${logo.logo?.altText}`}
                                                />
                                            </div>
                                        </a>
                                    )
                                }
                                {
                                    !logo.link && (
                                        <div className='image-wrapper'>
                                            <Image
                                                src={`${logo.logo.sourceUrl}`}
                                                width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                quality={100}
                                                alt={`${logo.logo?.altText}`}
                                            />
                                        </div>
                                    )
                                } 
                            </div>   
                        )})
                    }
                </div>
            )
          }
          {
          item.fourLogos && item.type === 'four-column-logos' && (
                <div className='logos-container'>
                    {
                        item.fourLogos.map((logo, index) => {return(
                            <div className='logo'>
                                {
                                    logo.link !== null && (
                                        <a href={`${logo.link}`} target='_blank'>
                                            <div className='image-wrapper'>
                                                <Image
                                                    src={`${logo.logo.sourceUrl}`}
                                                    width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                    height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                    quality={100}
                                                    alt={`${logo.logo?.altText}`}
                                                />
                                            </div>
                                        </a>
                                    )
                                }
                                {
                                    logo.link === null && (
                                        <div className='image-wrapper'>
                                            <Image
                                                src={`${logo.logo.sourceUrl}`}
                                                width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                quality={100}
                                                alt={`${logo.logo?.altText}`}
                                            />
                                        </div>
                                    )
                                } 
                            </div>   
                        )})
                    }
                </div>
            )
          }
          {
          item.fiveLogos && item.type === 'five-column-logos' && (
                <div className='logos-container'>
                    {
                        item.fiveLogos.map((logo, index) => {return(
                            <div className='logo'>
                                {
                                    logo.link && (
                                        <a href={`${logo.link}`} target='_blank'>
                                            <div className='image-wrapper'>
                                                <Image
                                                    src={`${logo.logo.sourceUrl}`}
                                                    width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                    height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                    quality={100}
                                                    alt={`${logo.logo?.altText}`}
                                                />
                                            </div>
                                        </a>
                                    )
                                }
                                {
                                    !logo.link && (
                                        <div className='image-wrapper'>
                                            <Image
                                                src={`${logo.logo.sourceUrl}`}
                                                width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                quality={100}
                                                alt={`${logo.logo?.altText}`}
                                            />
                                        </div>
                                    )
                                } 
                            </div>   
                        )})
                    }
                </div>
            )
          }
          {
          item.sixLogos && item.type === 'six-column-logos' && (
                <div className='logos-container'>
                    {
                        item.sixLogos.map((logo, index) => {return(
                            <div className='logo'>
                                {
                                    logo.link && (
                                        <a href={`${logo.link}`} target='_blank'>
                                            <div className='image-wrapper'>
                                                <Image
                                                    src={`${logo.logo.sourceUrl}`}
                                                    width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                    height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                    quality={100}
                                                    alt={`${logo.logo?.altText}`}
                                                />
                                            </div>
                                        </a>
                                    )
                                }
                                {
                                    !logo.link && (
                                        <div className='image-wrapper'>
                                            <Image
                                                src={`${logo.logo.sourceUrl}`}
                                                width={`${logo.logo?.mediaDetails.sizes[0]?.width}`}
                                                height={`${logo.logo?.mediaDetails.sizes[0]?.height}`}
                                                quality={100}
                                                alt={`${logo.logo?.altText}`}
                                            />
                                        </div>
                                    )
                                }  
                            </div>   
                        )})
                    }
                </div>
            )
          }
        </div>
    </div>
  )
}