import React from 'react'
export default function CallToAction({ item, smartImageLoader }) {
  return (
    <div className={`content-row ${item.noPadding ? 'no-padding' : ''} ${item.type}-type ${item.backgroundColor}-background`}>
        <div className="call-to-action-content container">
            <div>
                <div>
                    <div dangerouslySetInnerHTML={{__html: item.content}}></div>
                </div>
                <div>
                    {
                        item.buttons && item.buttons.length > 0 && item.buttons.map((button, index) => (
                            <div key={index} dangerouslySetInnerHTML={{__html: button.button}}></div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}